@import "~swiper/css";
@import "~swiper/css/navigation";
@import "~react-toastify/dist/ReactToastify.css";

// begin:: sport league detail
.scrollable-y {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.tip-types-container {
    position: relative;
    height: 45px;
    // &.live-matches {
    //     .tip-types {
    //         top: 0px
    //     }
    // }
}

.tip-types {
    background-color: #fff;
    position: absolute;
    top: 0px;
    left: 0;

    &.fixed {
        position: fixed;
        top: 56px !important;
        // width: calc(100% - 15px);
        width: 100%;
    }
    // position: fixed;

    // &.scrolling {
    //     transform: translateY(0);

    //     &.offset {
    //         transform: translateY(-106px);
    //     }

    //     &.up {
    //         transform: translateY(-200px);
    //     }
    // }
}

//

// begin:: Betting History
.betting-history-carousel {
    padding: 0 0 1.5rem;

    .carousel-inner {
        overflow: inherit;
    }

    .carousel-indicators {
        margin-bottom: 0;

        button {
            height: 12px;
            width: 12px;
            min-width: 12px;
            border-radius: 50%;

            &[aria-label=won] {
                background-color: var(--bs-success) !important;
            }

            &[aria-label=lost] {
                background-color: var(--bs-danger) !important;
            }

            &[aria-label=undecided] {
                background-color: var(--bs-warning) !important;
            }

            &[aria-label=cancelled] {
                background-color: var(--bs-gray-600) !important;
            }

            &[aria-label=cashout] {
                background-color: var(--bs-blue) !important;
            }
        }
    }
}

// end Betting History

// begin:: Header Language Dropdown
.language-dropdown {
    .dropdown-toggle {
        img {
            width: 24px;
            height: auto;
        }
    }
    .dropdown-item {
        img {
            width: 24px;
            height: auto;
        }
    }
}

// end:: Header Language Dropdown
