@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$enable-negative-margins: true;

$spacer: 1rem;
$spacers: (0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3,
  7: $spacer * 4,
  75: $spacer * .75);

$theme-colors: ("primary": #0070f3,
  "secondary": #084908,
  "tertiary": #f5f5f5,
  "black": #000000,
  "white": #ffffff,
  "info": tomato,
  "warning": #FFCC00,
  "danger": $danger,
  "success": #3AA843,
  "light": $light,
  "dark": #000000
);

$font-sizes: ("h2-font-size": 50px);

$form-validation-states: ("invalid": ("color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid));

$font-size-base: 1rem;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $font-size-base * 0.875,
  8: $font-size-base * 0.75,
  9: $font-size-base * 0.625,
  12: 12px,
  14: 14px,
  15: 15px,
  17: 17px
);

$utilities: ("border-opacity": (css-var: true,
    class: border-opacity,
    values: (10: .1,
      25: .25,
      50: .5,
      75: .75,
      100: 1)));

      .flex-center {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    
.bg-dark-light {
  background-color: #333;
}

.bg-gray-400 {
  background-color: #F6F6F6;
}

.border-gray-300 {
  border: 1px solid #dad4d4;
  box-shadow: 1px 2px 7px 0 #00000040;
}

@import "~bootstrap/scss/bootstrap.scss";

@import "./components.scss";
@import "./overrides.scss";