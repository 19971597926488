.nav-warning {
    .active {
        background-color: var(--bs-warning) !important;
        // color: #fff !important;
    }
}

.no-arrow {
    &::after {
        display: none;
    }
}

// .fw-medium {
//     font-weight: 500;
// }

.btn-success {
    color: #fff;
}

#casino-tab-tab-vivo, #casino-tab-tab-evo {
    &.nav-link{
        color: #171717;
        font-weight: 600;

        &.active {
            background-color: #23963e !important;
            color: #fff !important;
        }
    }
}